input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: none; */
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #fff0 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.admin-sidebar {
  margin: 0 auto;
  /* overflow: hidden; */
}

.admin-sidebar:hover {
  overflow-y: scroll;

}

.x-toggle::after {
  content: "NO";
}

input[type="checkbox"]:checked {
  color: #000;
}

.modal_image {
  height: 20px !important;
  width: 40px !important;
}

.styles-module_wrapper__1I_qj {
  z-index: 20 !important;
}

.side_logo {
  width: 115px;
  margin-left: 15px;
}

.center-form-title {
  display: inherit;
  margin: 0;
  position: relative;
  top: -12px;
  background-color: #ffffff;
  width: fit-content;
  padding: 0 8px;
}

.tab-title-style li .nav-link {
  color: #08ddfd;
  padding: 12px 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 0;
}

.tab-title-style li .nav-link.active {
  border-bottom: 2px solid #08ddfd;
  color: #08ddfd;
  background-color: #08ddfd0d; 
}

.table>:not(:first-child) {
  border-top: 0.0625rem solid rgb(45, 55, 72);
}

.center_form_tab {
  display: block !important;
}

.center-wizard .nav-item.active .nav-link {
  background-color: #ff35072b;
}

.center-wizard .nav-item:first-child .nav-link::before {
  content: " ";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -29px;
  margin-left: 1px;
  border-top: 28px solid transparent;
  border-bottom: 30px solid transparent;

  z-index: 1;
}

.center-wizard .nav-item:first-child .nav-link::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 50%;
  left: 100%;
  margin-top: -22px;
  border-top: 18px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 18px solid #ffe7e2;
  z-index: 2;
}

.center-wizard .nav-item.active .nav-link::after {
  border-left-color: #ffddd6 !important;
}

.center-wizard .nav-item {
  flex: 1 0 auto;
  position: relative;
}

.center-wizard .nav {
  justify-content: space-between;
}

.center-wizard .nav-item:nth-child(2) .nav-link {
  padding-left: 30px;
}

.center-wizard .nav-item .nav-link {
  background-color: #ffddd6b5;
}

.center-wizard .nav-item .nav-link:hover {
  color: #FF3700;
}

.modal-content {
  background: rgb(15, 21, 53);
}

.form-control,
.form-select {
  background: #0000;
  color: #fff;
  border-color: #4a5568;
  border-radius: 10px;
  font-size: 12px;
  padding: 8px 15px;
}
.form-select option {
  color: #000;
}
.form-select:focus {
  border-color: #07d4f3;
}
.modal-header,
.modal-footer {
  border-color: #4a5568;
}

.modal-header .btn-close {
  filter: invert(1);
  opacity: 1;
}

.form-label {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0.3rem;
}
.dashborad-icon {
  width: 50px;
  height: 50px;
  background: #08DDFD;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.ck.ck-toolbar {
  background: #0f1535 !important;
  border: 1px solid #4a5568 !important;
}
.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  color: #fff !important;}
  .ck.ck-editor__main>.ck-editor__editable{
    border-color: #4a5568 !important;
    background: #0f1535 !important;
    border-bottom-left-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
    color: #fff !important  ;
    min-height: 140px;
}
.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
  background: #f0f0f000 !important;
}
.ck.ck-list__item .ck-button .ck-button__label {
  color: #000;
}
.user-active {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: #2d3748;
  position: absolute;
  bottom: 14px;
  right: 14px;
  border: 2px solid #fff; 
}
.user-actives {
  background-color: #28b715;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #0000;
  opacity: .5;
}
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}

.ck-button.ck-on .ck-reset_all-excluded {
  color: #000 !important;
}
.ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
  z-index: 9999;
}
.ck.ck-link-actions.ck-responsive-form, form.ck.ck-link-form.ck-responsive-form {
  background-color: #0F1535;
}
.ck.ck-labeled-field-view__input-wrapper .ck-input {
  background-color: #0000;
}
.ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
  color: #0F1535 !important;
}